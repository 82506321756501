@import url(https://fonts.googleapis.com/css?family=IBM+Plex+Sans&display=swap);
body {
    margin: 0;
    /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
    /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
    /*  sans-serif;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/*html, body, #root{*/
/*  height: 100vh;*/
/*  width: 100vw;*/
/*}*/

#root {
    height: 100vh !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (max-width:600px){
    .top-card{
        background: white
    }
    .side-menu{
        max-width: 70px !important;
    }
    .off{
        display: none !important;
    }
    .export{
        margin-right: 0em !important;
    }
    .has-search .form-control-feedback{
        height: 1em !important;
        text-align: left !important;
        position: absolute !important;
    }
    .tops{
        margin-top: 18px;
    }
    .testing{
        margin-top: 20px;
    }
    .svg-top{
        display: none !important;
    }
    .full{
        margin: 4px !important;
    }
    select{
        display:none
    }
    .pagination{
        margin: 0em;
    }
}

table{
    margin-top: -20px !important;
}

.full{
    /*position: absolute;*/
    width: auto;
    /*height: 500px;*/
    /* max-height: 800px; */
    margin: 0px 30px;
    background-color: white;
    border-radius: 18px;
}
.top-card{
    height: 50px;
    width: 100% !important;
    padding: -4px;
    border-bottom: 1px solid lightgray;
}
.side-menu{
    padding: 80px 0 0 0;
    border-right: 1px solid lightgray;
    color: rgba(43, 28, 28, 0.5);
    font-weight: bold;
    flex: 1 1;
    max-width: 240px;
}
.profile-menu{
    /*padding: 90px 0 0 0;*/
    /*border-right: 1px solid lightgray;*/
    color: rgba(43, 28, 28, 0.5);
    font-weight: bold;
    flex: 1 1;
    /*margin-left: -1190px !important;*/
    /*max-width: 240px;*/
}
.card-class{
    border-left: 1px solid lightgray;
}
.student-id{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: bold;
    padding: 8px;
    font-size: 14px;
    /*line-height: 18px;*/
    /*display: flex;*/
    align-items: center;
    /*text-align: center;*/
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: rgba(43, 28, 28, 0.5);
}
/* .paginate{

} */
.pic-div{
    background-color: #fceba7;
    height:120px;
    width: 120px;
    border-radius: 50%;
    margin-bottom: 15px;
    /*border: 3px solid blue;*/
}
.status{
    background: #FFF0CE;
    border: 2px solid #FFB403;
    box-sizing: border-box;
    border-radius: 6px;
    /*padding: 12px;*/
    margin: auto;
    width: 180px ;
    height: 30px;
    color: #960C0C;
}

.invite-box{
    width: 25px;
    height: 25px;
    background: #FFDDD9;
    position: relative;
    cursor: pointer;
    border-radius: 6px;
    /*top: 10px;*/
    /*right: 10px;*/
    /*margin-top: -60px;*/
}
.comment-box{
    background: rgba(67, 18, 136, 0.2);
    width: 25px;
    height: 25px;
    position: relative;
    border-radius: 6px;
    cursor: pointer;
    /*top: 10px;*/
    /*right: 10px;*/

}
.decision-box{
    background: #C3F7E8;;
    width: 25px;
    height: 25px;
    position: relative;
   border-radius: 6px;
    cursor: pointer;
}

.invite{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    /*display: flex;*/
    align-items: center;
    letter-spacing: 0.02em;
    color: #FF7E1D;
    margin-top: 80px;
    cursor: pointer;
}
.add-comments{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    /*display: flex;*/
    /*align-items: center;*/
    letter-spacing: 0.02em;
    color: #431288;
    margin-top: 40px;
    cursor: pointer;
}
.make-decision{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    /*display: flex;*/
    /*align-items: center;*/
    letter-spacing: 0.02em;
    color: #00A998;
    margin-top: 40px;
    cursor: pointer;

}
.route-div{
    padding: 15px;
    /*background-color: #00A998;*/
}

.summary-first-div{
    background-color: #fceba7;
    height: 180px;
    border-radius: 18px;
}
.summary-second-div{
    border-radius: 18px;
    height: 330px;
    /*background-color: #FF7E1D;*/
}
.summary-second-div1{
    border-radius: 18px;
    min-width: 300px;
    min-height: 330px;
    background-color: #fceba7;
    margin-left: -10px;
}
.range-form {
    background: blue;
    width:250px;
}
.app-status{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height */
    /*display: flex;*/
    /*align-items: center;*/
    letter-spacing: 0.05em;
}

div a{
    color: gray;
}

/*div a:active{*/
/*    color: #431288;*/
/*    border-right: 3px solid #431288;*/
/*}*/
.side-active:hover {
    border-right: 5px solid #431288;
}


.active-indicator {
    width: 5px;
    background: white;
}

.active-indicator-fill {
    background: #431288;
}

#searchInput{
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHQSURBVHgBnZS/TsJQFMbPaTXWGHFSQ3pHBohEJ/EBdHOSzQF8AGU3voDuhMRV2WFz8wFk02BgYCwSHEtiaqC9nlNu8QYBq1/S3Pb++fWcr+cUYUp7QthDxDNDyiMJYKvpFl1tE6D+4jhPMEOoP2Rt+0oSBBarvgRQfnac7kzQjhA3NJxEm+mqfVpWu9PpuNlkMiNN85A251WUXYIVdFgIygpRog0XvAF9/7zZ67VmhcJpjxArIGWGDjaajlOI1kxeDAAqIdX3i/MgrL7rDja2th6WR6NjenFmO5F4e3fdcL8xAihF6SyCROJUaSirx8gKMAAxre5rEFOeZT0ioktR5VKpVGIMonz55tVxGnFBHBVBQqOt4dAeg/4plHLAo/T99RCEisymw1+kLOESiUBhSj7VSFzGrhAHUkr2pqvMByP4NrkYNyo6c82jKeVdNGewyRwVmZegUqj8BqM2uqVo9im1/sfqan0CCskAl8qrDMGqVOn5WenQfDVAPCXIEgaBseZ569H6pNf08tfOhwVKNWMrT1h9+mIGNfcmaD2HP0IXgquVmzOnz3PEctzI9xwJfZyq3sAIc8QVu+J5aeq/gWma7vRvgzPQYXNBcaTB4AtM7cZJTRZfjwAAAABJRU5ErkJggg==);
}

.tester{
    display: none;
}
.testing{
    display: block;
}

.div-active {
      border-right: 5px solid #431288;
  }
.summary-head{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 27px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    margin-bottom: 20px;

    color: #960C0C;


}

div .my-label{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: 0.05em;

    color: #2B1C1C;
}

.has-search .form-control {
    padding-left: 1.6rem;
    background: none !important;
    border: 0;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    /* width: 2.375rem; */
    height: 2.375rem;
    /* line-height: 2.375rem; */
    margin-top: 2px;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}

.searchOut{
    outline: none;
    /* border: none !important; */
    border-bottom: 1px solid rgba(43, 28, 28, 0.5) !important;
    border-top: none;
    border-left: none;
    border-radius: 0;
    border-right: none;
    box-shadow: none !important;
    width:180px;
    position: relative;
    bottom: 10px;
}

.off{
    color: rgba(43, 28, 28, 0.5)
}

.svgs{
    width: 75px;
}

.trContent{
    font-family: IBM Plex Sans;
font-style: normal;
font-weight: 600;
font-size: 17px;
line-height: 23px;
/* display: flex; */
align-items: center;
letter-spacing: 0.02em;
height: 50px;
border-top: 1px solid lightgray;
color:rgba(43, 28, 28, 0.85);
}
/* th{
     padding-right: 100px; 
} */
td{
    text-transform: capitalize;
}

.export {
    cursor: pointer;
    color: #00A998;
    background: none;
    border:none;
    font-weight: 600;
    font-size: 14px;
    margin-right: -138px;
    margin-top: -18px;
    margin-left: -65px;
    z-index: 2;
    width: 85px;
}

.pagination {
    float: right;
    margin-right: 120px;
  }
  
  .pagination a {
    color: black;
    float: left;
    border:none;
    padding: 8px 16px;
    text-decoration: none;
    float: right;
    margin-right: 15px;
    margin-bottom: 10px;
      background: rgba(43, 28, 28, 0.05);
      border-radius: 4px;
  }

  .pagination a{
    color: black;
    float: left;
    border:none;
    padding: 8px 16px;
    text-decoration: none;
    float: right;
    margin-right: 5px;
  }
  
  .pagination a.active {
    background-color: #960C0C;
    padding: 10px 20px;
    color: white;
  }

  .sideBut{
    margin-bottom: 45px !important
  }

  th{
    margin:0.9rem 1.2rem 0.9rem 0 !important;
  }
  .bio-title{
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 16px;
      color: #2B1C1C;
  }
  .bio-data{

      font-size: 13px;
      line-height: 18px
  }
  .more-box{
      /*margin-right: -20px !important;*/
      padding-bottom: 25px !important;
  }
  .question-info{
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;

      /* identical to box height */
      display: flex;
      align-items: center;
      letter-spacing: 0.05em;

      color: #00A998;
  }
  .question-label{
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: 300;
      font-size: 15px;
      line-height: 21px;

      color: #000000;
  }
  .score-circle{
      border-radius: 50%;
      background: #960C0C;
  }
@media screen and (max-width: 600px) {
    .cover-div {
        background: white !important;
        margin: 1px 0 0 20px;
        min-height: 60vh;
        min-width: 100vw;
        max-height: 200vh;
        padding: 25px 30px 0;
    }

    .image-div {
        min-width: 480px;
        background: white;
        border-radius: 12px;
    }

    .newOptionDiv {

    }
}

@media screen and (min-width: 800px) {
    .cover-div {
        padding: 30px;
        width: 850px;
        background: white;
        align-self: center;
        margin-top: 10% !important;
    }

    .image-div {
        height: 400px;
        width: 550px;
        background: white;
        border-radius: 12px;
    }

    .newOptionDiv {

    }
}

#nextButton {
    background: #FF7E1D;
    border-radius: 6px;
    width: 100px;
    height: 35px;
    color: white;
    border: none;
}

#nextButton:hover {
    background: #FF8f8f;
    color: white;
}

.question {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    /*line-height: 47px;*/
    /*display: flex;*/
    align-items: center;
    color: #960C0C;
    padding: 0;
}

.timer {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    /*line-height: 27px;*/
    /*display: flex;*/
    align-items: center;
    text-align: right;
    color: #FF2E2E;
}

.options {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    /*line-height: 27px;*/
    /*display: flex;*/
    align-items: center;
    color: #2B1C1C;
}

.pagination {
    float: left !important;
}

.image-div {
    min-height: 0;
    min-width: 200px;
    background: white;
    border-radius: 12px;
}

.mover {
    height: 5px;
    /*margin-top: 12px;*/
    /*width: 10px;*/
    /*border-bottom: 3px solid gray;*/
    background: #FFB403;
}

hr {
    margin-top: 0;
    position: relative;
    top: 19px;
    width: 100px
}

.spar {
    margin-left: 10px;
    position: relative;
    top: -5px
}

.mb5s {
    margin-bottom: 8rem !important;
}

.newOptionDiv {
    margin: 0 0 35px 15px;
}

.MuiFormGroup-root {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px !important;
    flex-direction: column;
}

:root {
    --primary-color: #FF2E2E;
}


body {
    font-family: 'IBM Plex Sans', sans-serif;
    /*overflow: hidden;*/
}


.create-new-pswd, .register {
    width: 90% !important;
}

.welmsg {
    font-weight: 500 !important;
    margin-bottom: 80px !important;
}

.welreset {
    font-weight: 500 !important;
    margin-bottom: 20px !important;
}

h3 {
    font-weight: 500;

}

input, button, select {
    border-radius: 4px;
    outline: none;

}

input::-webkit-input-placeholder {
    font-weight: lighter;
    color: #000;
}

input:-ms-input-placeholder {
    font-weight: lighter;
    color: #000;
}

input::placeholder {
    font-weight: lighter;
    color: #000;
}

input, select {
    background: white !important;
    padding: 16px;
    color: #2B1C1C;
    font-weight: 500;
    outline: none;
}


.agreelabel {
    display: flex;
    margin-bottom: 0px !important;
    margin-top: 12px;
    /*margin-left: 5px;*/
}

.tick {
    padding-left: 0px !important;
    margin-top: 20px;
}

element.style {
    width: 320px !important;
    height: 59px !important;
}

.bt, .bt-apply, .bt-createpass {
    background: #FF2E2E;
    color: #fff;
    border: 1px solid #FF2E2E;
    font-weight: bold;
    letter-spacing: 1.5px;
    transition: all .5s ease-in-out;
}

.bt:hover {
    background: #960C0C;
    border-color: #960C0C;
    /*opacity: .8;*/
}

.bt:active, button:focus {
    outline: none !important;
}
.payment{
    width: 105% !important;
}


.bt:disabled {
    background: #960C0C;
    border-color: #960C0C;
    /*opacity: .8;*/
}


.partner-btn button {
    background: transparent !important;
    color: #FF2E2E;
    font-weight: bold;
    border: 2px solid #FF2E2E;
    transition: all .5s ease-in-out;
}

.partner-btn button:hover {
    color: #FFF !important;
    background: #FF2E2E !important;
    background: var(--primary-color) !important;
    border-color: #FF2E2E;
    border-color: var(--primary-color);
}

img {
    vertical-align: middle;
    height: auto;
    width: 100%;
    max-width: 720px;
    max-height: 320px;

}

@media (max-width: 768px) {
    .create-new-pswd, .register {
        width: 100% !important;
        overflow: hidden !important;
    }
}

#mailErr {
    font-size: 12px;
}

@media only screen and (max-width: 600px) {

    span {
        position: relative !important;

    }


}

.MuiFormLabel-root.Mui-focused {
    color: #FF2E2E !important;
    font-family: IBM Plex Sans, serif !important;
    font-style: normal !important;
    font-style: normal !important;
}

.MuiOutlinedInput-input {
    color: black !important;
}

.terms {
    cursor: pointer;
    color: red;
    font-weight: bold;
    margin-left: 5px;
    font-size: 13px;
}

.terms:hover {
    text-decoration: none;
    color: #960C0C;
}

.agree {
    /*letter-spacing: 0.02em  !important;*/
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    transition: all .3s ease-in-out;
    /*margin-left: 30px;*/
}

.forget {
    text-align: center;
    margin-top: 25px;
    margin-bottom: 55px;
    letter-spacing: 0.02em !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    font-family: IBM Plex Sans;

}

.bigbt {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 9px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.05em;

    margin-top: 30px;

    color: #FFFFFF;
}

label {
    margin-right: 10px !important;
    margin-bottom: .0rem !important;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 15px;
}

.MuiTypography-body1 {
    font-family: IBM Plex Sans !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 15px !important;
    line-height: 15px !important;
}

.newField .MuiOutlinedInput-input {
    padding: 25px 0 20px 14px !important;
    /*border: 1px solid rgba(150, 12, 12, 0.5);*/

}

.genders {
    margin-right: 30px !important;
}

.MuiSvgIcon-root {

    height: 0.8em !important;
}

.MuiOutlinedInput-input:focus {
    border: none;
}

.MuiOutlinedInput-input:hover:focus {
    border: none !important;
}

.MuiOutlinedInput-input:hover:active {
    border: none !important;
}

.MuiFormGroup-root {
    display: block !important;
}

.MuiFormLabel-root {
    font-family: IBM Plex Sans !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 15px !important;
    top: -5px !important;

    color: #5f5e5e !important;
}

.MuiFormControl-root {
    display: block !important;
}

.newField .MuiSelect-select.MuiSelect-select {
    padding-right: 24px !important;
    margin: 0 0 0 0 !important;
    padding-bottom: 23px !important;
}

.MuiSelect-selectMenu {
    background: white !important;
}

.form-title {
    margin-bottom: 40px;
    font-weight: bold;
}

/*New CSS styling*/
.form-container {
    min-height: 100% !important;
    background-color: #FFFFFF !important;
    /*height: 100% !important;*/
}
/*.react-tel-input .form-control{*/
/*    height: 80% !important;*/
/*}*/
.left-content {
    width: 385px;
}

.form {
    margin-top: 20px;
}

.MuiInputBase-input {
    height: 1px !important;
}

.site-logo, .partner-btn {
    padding-top: 27px;
}

.site-logo {
    width: 380px;
}

.left-form-div {
    flex: 4 1;
}

.site-logo, .partner-btn {
    height: 10.11%;
}

.form-img, .form-holder {
    height: 88.89%;
}

.form-img img {
    margin-bottom: 100px;
}

.right-ill-div {
    background: #FFF2C7;
    flex: 6 1;
}


.MuiCheckbox-colorSecondary.Mui-checked {
    color: #960C0C !important;
}

.MuiCheckbox-root {
    color: #960C0C !important;;
}

@media (max-width: 1100px) {
    .left-form-div {
        flex: 5 1;
    }

    .right-ill-div {
        flex: 5 1;
    }

    .login-right {
        display: none !important;
    }
}

@media (max-width: 860px) {
    .left-form-div {
        flex: 6 1;
    }

    .right-ill-div {
        flex: 4 1;
    }
}

@media only screen and (max-width: 600px) {
    .conPhone .newPhone {
        width: 170px !important;
    }

}

@media (max-width: 859px) {
    .right-ill-div {
        display: none;
    }

    .react-tel-input .form-control {
        width: 0 !important;
        /*margin-bottom: 25px ;*/
    }

    .left-form-div {
        width: 100%;
        height: 100%;
    }

    .err-image svg {
        width: 100%;
        margin-top: -120px;

    }
}

@media (max-width: 420px) {
    .left-content {
        width: 290px;
    }


}

/*.react-tel-input .form-control {*/
/*    font-size: 9px;*/
/*    background: #FFFFFF;*/
/*    border: 1px solid #CACACA;*/
/*    border-radius: 5px;*/
/*    margin-top: 18px !important;*/
/*    outline: none;*/
/*    transition: box-shadow ease .25s, border-color ease .25s;*/
/*}*/
.phone {
    pointer-events: none !important;
    width: 110px !important;
    height: 60px !important;
    border: 1px solid rgba(150, 12, 12, 0.5) !important;
}


.arrow {
    border-top: 3px solid red;
}

.react-tel-input :focus {
    border-color: #FF2E2E !important;
    box-shadow: 0 0 0 1px #FF2E2E !important;

}

.MuiSelect-outlined.MuiSelect-outlined {

    padding-bottom: 17px !important;
}

.conPhone .newPhone {
    padding-left: 15px !important;
    height: 43px !important;
    border-top-left-radius: 1px !important;

    margin-left: 10px !important;


}

.newPhone {
    width: 262px !important;
}

.conPhone .selected-flag {
    display: none !important;
    margin-left: -40px !important;
}

.conDial .phone + div:before {
    visibility: hidden !important;
    /*padding-left: 30px !important;*/
}

.conPhone .newPhone + div:before {
    visibility: hidden !important;

}

/*.react-tel-input .selected-flag:focus+div:before{*/
/*    color: #FF2E2E !important;*/
/*    visibility: visible!important;*/
/*    content: 'Phone ' !important;*/
/*    left: 15px !important;*/
/*    font-size: 13px !important;*/

/*}*/


.conPhone .newPhone:focus + div:before {
    color: #FF2E2E !important;
    visibility: visible !important;
    content: 'Phone number' !important;
    left: 15px !important;
    font-size: 12px !important;

}


.newPhone:not(:-ms-input-placeholder) + div:before {
    color: #5f5e5e !important;
    visibility: visible !important;
    content: 'Phone number ' !important;
    left: 15px !important;
    font-size: 12px !important;
}


.newPhone:not(:placeholder-shown) + div:before {
    color: #5f5e5e !important;
    visibility: visible !important;
    content: 'Phone number ' !important;
    left: 15px !important;
    font-size: 12px !important;
}

.conPhone .newPhone::-webkit-input-placeholder {
    color: black !important;
}

.conPhone .newPhone:-ms-input-placeholder {
    color: black !important;
}

.conPhone .newPhone::placeholder {
    color: black !important;
}

.conDial .selected-flag {

    margin-top: 8px !important;
}

.react-tel-input .selected-flag .flag {
    margin-top: -20px !important;
}

option:checked,
option:hover {
    box-shadow: 0 0 10px 100px #FF2E2E inset;
    color: white;
}


.age .MuiSelect-selectMenu {

    min-height: 0em !important;

}


input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.err-image {
    justify-content: center;
    height: 80vh !important;
    align-items: center;
    flex-direction: column;
    display: flex;
}

@media screen and (max-width: 600px) {
    .partners {
        display: none;
    }

    .patDiv {
        display: none;
    }
}

@media screen and (width: 768px) {
    .bac {
        /* max-width: 25.333333%!important; */
    }

    .partners {

    }
}

.bac {
    background: #FFF0CE;
}

.circle {
    background: white;
    height: 10vh;
    width: 10vh;
    border-radius: 50%;
    margin-top: 50px;
    margin-left: 65px;
    padding-right: 0px !important;
    padding-left: 0px !important;
}

.thumb {
    margin-left: 55px !important;
    /*margin-bottom: 12px!important;*/
    margin-top: 50px !important;
}

.box {
    /*padding: 50px;*/
    min-height: 72vh;
    overflow: hidden;
    top: 5px;
    padding-bottom: 15px;
}

.text-area {
    height: 30vh;

    /*background: white;*/
}

.submit-text {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    margin-left: 70px;
    margin-right: 70px;

    /*line-height: 60px;*/
    /* or 111% */

    display: flex;
    align-items: center;
    letter-spacing: -0.02em;

    color: #960C0C;
}

.p-text {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    margin-left: 70px;
    margin-right: 80px;

    margin-top: 30px;
    font-size: 13px;
    line-height: 175%;
    /* or 37px */

    letter-spacing: -0.02em;

    color: #2B1C1C;
}

.next-button {
    background: #FF2E2E;
    border: none;
    color: white;
    margin: 5.83% 46.88% 1.5% 20.62% !important;
    height: 52px;
    width: 180px
}

.circle2 {
    background: #FFF2C7 !important;
}

.newback {
    background: #F6F6F6 !important;
}

.rowl {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    background: white !important;
}

.side-new {
    background: white !important;
}

.prev-button {
    background: white;
    border-radius: 6px;
    /*padding: 13px;*/
    color: #FF2E2E;
    border: 2px solid #FF2E2E;
    margin-top: 15px;
    margin-left: 70px;
    height: 52px;
    width: 180px
}

.submit {
    background: #FF2E2E !important;
    /*border-radius: 6px;*/
    /*padding: 13px;*/
    /*border: none;*/
    color: white !important;
    /*margin-top: 70px;*/
}

.mt-md-5 {
    padding-bottom: 50px;
}

.back-to-dashboard {
    transition: all .5s ease;
}

.back-to-dashboard:hover {
    background: #FF2E2E;
    color: white;
}
